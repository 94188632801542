.title {
    font-size: 40px;
    font-weight: 600;
    margin-top: 5%;
    text-align: left;
}

.info-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 4%;
    height: 40%;
}

.section {
    background-color: white;
    width: 90%;
    height: 80%;
    border-radius: 10px;
    box-shadow: 1px 1px 5px 0px #00000022;
    display: flex;
    align-items: center;
}

.info-icon {
    height:65%;
    margin: 0 7%;
}

.info-label {
    font-size: 20px;
}

.info-value {
    font-size: 30px;
    font-weight: 600;
}

.inner-card {
    padding: 2% 5%;
    display: flex;
}

.tips-container {
    height: 40%;
}

.tips {
    width: 95%;
    height: 100%;
    padding-left: 4%;
    overflow-y: scroll;
}

.share-container {
    height: 40%;
    width: 100%;
    margin-left: 4%;
    margin-top: 4%;
}

.share {
    width: 100%;
    height: 93%;
    overflow-y: scroll;
    padding: 4%;
    align-items: flex-start !important;
}

.leaderboard-outer-container {
    width: 100%;
    height: 55%;
    margin-left: 4%;
}

.leaderboard {
    height: 90%;
    width: 100%;
    margin-top: 5%;
    padding: 3%;
    overflow-y: scroll;
}

.dashboard-column {
    height: 100%;
    width: 50%;
}

.tips-title {
    text-align: left;
    width: 100%;
    margin-top: 3%;
    color: #109539;
    font-size: 20px;
    font-weight: 600;
}

.tips-detail {
    text-align: left;
    width: 100%;
    margin-top: 2%;
    font-size: 18px;
}

.tip {
    text-align: left;
    width: 100%;
    margin-top: 5%;
}

.share-title {
    color: #109539;
    width: 100%;
    text-align: left;
    font-size: 30px;
    font-weight: 600;
}

.share-detail {
    width: 100%;
    text-align: left;
    margin-top: 3%;
}

.referral-code {
    border: 1px solid #00000055;
    width: 70%;
    height: 2.5em;
    border-radius: 4px 0px 0px 4px;
    margin-top: 3%;
    font-weight: 600;
}

.copy-button {
    background-color: #109539;
    height: 2.5em;
    border-radius: 0px 4px 4px 0px;
    width: 15%;
    margin-top: 3%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
}

.leaderboard-title {
    color: #109539;
    font-size: 24px;
    font-weight: 600;
}

.leaderboard-row {
    padding: 0.2em 0;
    background-color: #F5F7FA;
    width: 90%;
    margin: 5%;
    height: 12%;
    border-radius: 10px;
    font-size: 18px;
}

.my-position {
    background-color: #109539;
    color: white;
}

.share-modal {
    margin-top: 20%;
}

@media screen and (max-width: 1400px) {

    .dashboard-column {
        height: auto;
    }

    .info-section {
        height: auto;
        margin-top: 4%;
        margin-left: 1em;
    }

    .info {
        padding: 1em;
    }
    
    .tips-container {
        height: auto;
        padding: 1em;
    }

    .tips {
        padding: 0.5em;
    }

    .share-container {
        height: auto;
        padding: 1em;
        width: 95%;
        margin-left: 0;
        margin-top: 0;
    }

    .share {
        padding: 1em;
    }

    .leaderboard-outer-container {
        height: auto;
        padding: 1em;
        width: 95%;
        margin-left: 0;
        margin-top: 0;
    }

    .leaderboard {
        padding: 1em;
        height: auto;
        margin-top: 0;
    }

    .leaderboard-row {
        padding: .6em;
    }

    .title {
        margin-top: 1%;
        margin-left: .5em;
    }

    .dashboard-column {
        width: 100%;
        margin-left: 2%;
    }

    .inner-card {
        display: inline;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 5%;
    }
    
}

@media screen and (max-width: 600px) {

    .title {
        margin-top: 5%;
        font-size: 30px
    }

    .info-icon {
        width: 30%;
    }

    .info-label {
        font-size: 16px;
    }
    
    .info-value {
        font-size: 20px;
    }

    .copy-button {
        width: 25%;
    }

}

@media screen and (max-width: 400px) {
    .info-value {
        font-size: 16px;
    }  
}

@media screen and (max-height: 600px) {
    .info-section {
        margin-top: 2%;
    }
}