.main-leaderboard {
    box-shadow: 1px 1px 5px 0px #00000022;
    height: 77%;

    max-height: 80vh;
    margin-top: 5%;
    border-radius: 10px;
    overflow-y: scroll;
    background-color: white;
    font-size: 14px;
}

.podium {
    display: flex;
    width: 80%;
    margin: 2em auto 1em;
    justify-content: space-around;
}

.podium-position {
    background-color: #B4B4B4;
    border-radius: 100%;
    position: relative;
}

.podium-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
}

.third {
    border: 3px solid #C97D32;
    height: 6em;
    width: 6em;
    margin-top: 2em;
}

.second {
    border: 3px solid #F5F7FA;
    height: 6em;
    width: 6em;
    margin-top: 2em;
}

.first {
    border: 3px solid #FFCA28;
    height: 7em;
    width: 7em;
}

.podium-position-indicator {
    position: absolute;
    left: 30%;
    width: 40%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 77%;
    border-radius: 100%;
    font-weight: 700;
}

.crown {
    position: absolute;
    bottom: 92%;
    width: 35%;
    left: 32.5%
}

.position-first {
    background-color: #FFCA28;
}
.position-second {
    background-color: #F5F7FA;
}
.position-third {
    background-color: #C97D32;
}

.podium-name {
    margin-top: 1.5em;
    font-weight: 700;
}

.podium-amount {
    color: #109539;
    font-weight: 700;
    margin-top: 0.5em;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nearby-leaderboard {
    background-color: white;
    padding: 2%;
    margin-top: 3%;
    height: 51%;
    border-radius: 10px;
    box-shadow: 1px 1px 5px 0px #00000022;
    overflow-y: scroll;
}

.first-column {
    padding-left: 2.5em;
}

.share-section-leaderboard {
    margin: 1em 0;
}

@media screen and (min-width: 1400px) {
    .nearby-leaderboard {
        min-height: 55%;
    }
}


@media screen and (max-width: 900px) {
    .main-leaderboard {
        width: 95%;
        margin-left: 0;
        max-height: 50vh;
    }

    .first-column {
        padding-left: 1.5em;
    }
}

@media screen and (max-width: 500px) {
    .main-leaderboard {
        font-size: 12px;
    }

    .podium-position {
        width: 4em;
        height: 4em;
    }

    .podium-name {
        font-size: 12px;
    }

    .podium-amount {
        font-size: 12px;
    }

    .first-column {
        padding-left: 1.5em;
    }

    .nearby-leaderboard {
        margin-bottom: 2em;
    }
    
}