.blogs-page {
    margin: 1em;
    text-align: left;
    background: white;
    border-radius: 8px;
    padding: 2em;
    box-shadow: 1px 1px 5px 0px #00000022;
    max-width: 1000px;
    z-index: 1;
}

.page-title {
    padding-bottom: 1em;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
}

.blog-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.blog {
    padding: 1em;
    background: white;
    border-radius: 10px;
    flex: 1 1 45%;
    box-shadow: 1px 1px 5px 0px #00000022;
    height: 200px;
    display: flex;
    flex-direction: column;
}

.blog-title {
    font-weight: bold;
    margin-bottom: .5em;
    font-size: 20px;
}

.blog:hover {
    cursor: pointer;
}

.blog-preview {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 50px;
}