.heading {
  font-size: 56px;
  font-weight: 600;
  color: #393939;
  margin-top: 2%;
}

.subheading {
  font-size: 26px;
  color: #393939;
  text-align: left;
}

.subheading-details {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;
  font-size: 18px;
}

.home-tile {
  background-color: white;
  width: 45%;
  box-shadow: 0px 4px 10px 0px #0000001a;
  border-radius: 20px;
  z-index: 1;
  position: relative;
  min-width: 400px;
}

.tiles-container {
  display: flex;
  width: 60%;
  min-width: 1000px;
  margin: auto;
  margin-top: 3%;
  justify-content: space-between;
}

.tile-title {
  font-weight: 600;
  font-size: 38px;
  height: 14%;
  color: #393939;
}

.tile-description {
  font-size: 25px;
  color: #393939;
  padding: 3% 5%;
  height: 33%;
}

.icon {
  margin: 10% 0% 5%;
  height: 15%;
  aspect-ratio: 1;
}

.button {
  height: 3em;
  margin-bottom: 2em;
  width: 60%;
  border: none;
  margin: 4% 0 10%;
  border-radius: 30px;
  color: white;
  font-weight: 600;
  font-size: 18px;
}

.background-stripe {
  z-index: 0;
  width: 100%;
  position: absolute;
  left: 0;
  top: 20%;
}

.house {
  position: absolute;
  right: 3%;
  top: 55%;
  width: calc(min(20vw - 100px, 400px));
  z-index: 2;
}

.house2 {
  position: absolute;
  left: 0%;
  top: 65%;
  width: calc(min(23vw - 100px, 400px));
  z-index: 2;
}

.referral {
  position: relative;
}

.people {
  position: absolute;
  right: -7%;
  top: -13%;
  z-index: 2;
  height: 25%;
}

.ribbon-div {
  position: absolute;
  right: 82%;
  top: -10%;
  z-index: 2;
  height: 30%;
  width: 27%;
}

.bullet-points {
  font-size: 16px;
  width: 100%;
  font-weight: bold;
  text-align: left;
  margin: auto;
  margin-bottom: 0.4em;
}

.small-text {
  font-size: 16px;
  width: 100%;
  text-align: left;
}

.bullet-container {
  margin: auto;
  width: max-content;
}

.home-blog-section {
  margin: 0 10%;
}

.home-blog-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.home-blog {
  background: white;
  border-radius: 10px;
  height: 300px;
  max-height: 400px;
  padding: 1em;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.home-blog-title {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  line-height: 1.15;
  overflow: hidden;
  text-overflow: ellipsis;
}

.home-blog-text {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.home-blog-button {
  margin-top: 8px;
  padding: 0.25em 1em;
  background: rgb(16, 149, 57);
  border-radius: 24px;
  color: white;
  border: none;
}

@media screen and (max-width: 1390px) {
  .house {
    display: none
  }

  .house2 {
    display: none;
  }
}

@media screen and (max-width: 1130px) {
  .tiles-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 10%;
    min-width: 0;
  }

  .home-tile {
    margin: auto;
    margin-bottom: 10%;
    width: 70%;
    min-width: 0;
    padding: 0 5%;
  }

  .background-stripe {
    z-index: 0;
    width: 100%;
    position: absolute;
    top: 40%;
  }

  .people {
    width: 15%;
    height: auto;
    right: -5%;
    top: -80px;
  }

  .heading {
    font-size: 45px;
  }

  .subheading {
    font-size: 24px;
  }

  .tile-title {
    font-size: 30px;
  }

  .tile-description {
    font-size: 20px;
  }

  .button {
    padding: 2% 0;
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  .heading {
    font-size: 36px;
  }

  .subheading {
    font-size: 18px;
  }

  .subheading-details {
    margin-bottom: 3rem;
    font-size: 14px;
  }

  .bullet-points {
    font-size: 12px;
    font-weight: bold;
  }
  .small-text {
    font-size: 12px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1000px) {
  .people{
      right: -5%;
      top: -13%;
      height: 25%;
  }
}

@media only screen and (max-width: 899px) {
  .people{
      right: -5%;
      top: -15%;
      height: 30%;
  }
}

@media only screen and (max-width: 650px) {
  .people{
      width: 80px;
  }
}