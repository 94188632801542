.blog-page {
    margin: 3em 1em;
    text-align: left;
    background: white;
    border-radius: 8px;
    padding: 2em;
    box-shadow: 1px 1px 5px 0px #00000022;
    max-width: 1000px;
    z-index: 1;
}

.blog-page h2 {
    font-size: 20px;
    font-weight: bold;
    color: rgb(16, 149, 57);
}

.blog-page .home-blog-container {
    min-width: 0;
    width: 100%;
}

.blog-page h2 {
    font-size: 20px;
    font-weight: bold;
    color: rgb(16, 149, 57);
}

.header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.back-button {
    border: none;
    background: transparent;
    color: #0b9521;
    text-decoration: none;
}

.blog-page-title {
    font-size: 24px;
    font-weight: bold;
}

.blog-images {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}