.modal-content {
    padding: 5%;
}

.modal-dialog {
    width: 75%;
    margin: auto;
}

.modal-button, .modal-button:hover, .modal-button:active, .modal-button:disabled, .modal-button:focus {
    background-color: #109539 !important;
    border: none;
    margin: 0 5%;
}

@media screen and (max-width: 450px){

    .form-card-sign-in, .form-card-sign-up {
        height: 90vh !important;
        width: 95% !important;
        z-index: 1;
        align-items: center !important;
    }

    .housr-logo-sign-in {
        width: 12% !important
    }

    .title-sign-in {
        font-size: 28px !important;
        width: 90% !important;
        margin-left: 5%;
    }

    .sign-in-form {
        width: 85% !important;
    }

    .key-container {
        display: none !important
    }

    .house-sign-in-container {
        display: none !important;
    }
}

@media screen and (max-width: 500px) and (min-height: 700px) {
    .heading-sign-up {
        padding-top: 10vh !important;
    }
}

@media screen and (min-width: 451px) and (max-width:500px) {

    .form-card-sign-in, .form-card-sign-up {
        height: 80vh !important;
        width: 85% !important;
        z-index: 1;
        align-items: center !important;
    }

    .housr-logo-sign-in {
        width: 12% !important
    }

    .title-sign-in {
        font-size: 28px !important;
        width: 85% !important;
        margin-left: 7.5%;
    }

    .sign-in-form {
        width: 85% !important;
    }

    .key-container {
        display: none !important
    }

    .house-sign-in-container {
        display: none !important;
    }
}

@media screen and (min-width: 501px) and (max-width:576px) {

    .form-card-sign-in, .form-card-sign-up {
        height: 80vh !important;
        width: 75% !important;
        z-index: 1;
        align-items: center !important;
    }

    .housr-logo-sign-in {
        width: 12% !important
    }

    .title-sign-in {
        font-size: 28px !important;
        width: 85% !important;
        margin-left: 7.5%;
    }

    .sign-in-form {
        width: 80% !important;
    }

    .key-container {
        display: none !important
    }

    .key-image {
        height: 7vh !important;
    }

    .house-sign-in-container {
        display: none !important;
    }

    .house-sign-in {
        height: 20vh !important;
    }

}

@media screen and (max-width: 991px) {
    .sign-up-form {
        width: 100% !important;
    }

    .container-sign-up {
        width: 100% !important;
    }

    .heading-sign-up {
        padding-top: 10% !important;
        width: 100% !important;
    }

    .buttons-container-sign-up {
        padding-bottom: 5vh;
    }
}

@media screen and (min-width: 577px) and (max-width: 768px){
    .form-card-sign-in, .form-card-sign-up {
        height: 80vh !important;
        width: 68% !important;
        z-index: 1;
        align-items: center !important;
    }

    .housr-logo-sign-in {
        width: 12% !important
    }

    .title-sign-in {
        font-size: 28px !important;
        width: 85% !important;
        margin-left: 7.5%;
    }

    .sign-in-form {
        width: 80% !important;
    }

    .key-container {
        top: 88vh !important;
        left: -43vw !important;
    }

    .key-image {
        height: 7vh !important;
    }

    .house-sign-in-container {
        bottom: -78vh !important;
        right: -20vw !important;
    }

    .house-sign-in {
        height: 20vh !important;
    }
}

@media screen and (min-width: 769px) and (max-width: 1100px){
    .form-card-sign-in{
        height: 80vh !important;
        width: 400px !important;
        z-index: 1;
        align-items: center !important;
    }

    .form-card-sign-up {
        width: 55vw !important;
    }

    .housr-logo-sign-in {
        width: 12% !important
    }

    .title-sign-in {
        font-size: 28px !important;
        width: 85% !important;
        margin-left: 7.5%;
    }

    .sign-in-form {
        width: 80% !important;
    }

    .key-container {
        top: 85vh !important;
        left: -30vw !important;
    }

    .key-image {
        height: 12vh !important;
    }

    .house-sign-in-container {
        bottom: -70vh !important;
        right: -25vw !important;
    }

    .house-sign-in {
        height: 25vh !important;
    }
}

@media screen and (min-width: 992px) and (max-width: 1100px) {
    .form-card-sign-up {
        width: 90vw !important;
    }
}

@media only screen and (min-width: 1101px) and (max-width: 1400px){

    .form-card-sign-in{
        height: 80vh !important;
        width: 400px !important;
        z-index: 1;
        align-items: center !important;
    }

    .housr-logo-sign-in {
        width: 12% !important
    }

    .title-sign-in {
        font-size: 28px !important;
        width: 85% !important;
        margin-left: 7.5%;
    }

    .sign-in-form {
        width: 80% !important;
    }

    .key-container {
        top: 75vh !important;
        left: -30vw !important;
    }

    .key-image {
        height: 12vh !important;
    }

    .house-sign-in-container {
        bottom: -60vh !important;
        right: -25vw !important;
    }

    .house-sign-in {
        height: 30vh !important;
    }
}

@media screen and (min-width: 1401px) {
    .buttons-sign-up {
        width: 75% !important;
    }

    .info-tile-sign-up {
        width: 60% !important;
    }
}