.profile-full-container {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh !important;
  overflow: hidden;
  position: relative;
}

.flex-direction-profile {
  display: flex;
  flex-direction: row;
}

.signup-header {
  text-align: left;
  padding-left: 1em;
  padding-top: 15px;
  padding-bottom: 15px;
}

.profile-tile {
  margin-left: 3rem !important;
  width: 40%;
  display: flex !important;
  background-color: white;
  flex-direction: column !important;
  height: fit-content;
  box-shadow: 0px 4px 10px 0px #0000001a;
  border-radius: 20px;
  z-index: 1;
}

.background-stripe-right-profile {
  z-index: 0;
  width: 30%;
  position: absolute;
  right: -10%;
  top: 15%;
}

.background-stripe-left-profile {
  z-index: 0;
  width: 20%;
  position: absolute;
  left: 0;
  top: 70%;
}

.profile-container {
  display: flex;
  height: 100% !important;
  width: 100% !important;
  margin-top: 3em;
}

.housr-button-reverse {
  background-color: #ffffff;
  border-color: #109539;
  color: #109539;
  font-weight: 600;
  border-radius: 40px;
  height: 5vh;
  margin-top: 5vh;
}

.housr-button-reverse:hover {
  background-color: #ffffff;
  border-color: #109539;
  border-radius: 40px;
  font-weight: 600;
  height: 5vh;
  margin-top: 5vh;
}

.housr-button-reverse:focus {
  background-color: #ffffff;
  border-color: #109539;
  border-radius: 40px;
  font-weight: 600;
  height: 5vh;
  margin-top: 5vh;
}

@media screen and (max-width: 1100px) {
  .profile-tile {
    margin-left: 3rem !important;
    width: 40%;
    display: flex !important;
    background-color: white;
    flex-direction: column !important;
    height: fit-content;
    box-shadow: 0px 4px 10px 0px #0000001a;
    border-radius: 20px;
  }
}

@media screen and (max-width: 1100px) {
  .housr-button-reverse {
    background-color: #ffffff;
    border-color: #109539;
    color: #109539;
    font-weight: 600;
    font-size: 14px;
    border-radius: 40px;
    height: 5vh;
    margin-top: 5vh;
  }

  .housr-button-reverse:hover {
    background-color: #ffffff;
    border-color: #109539;
    border-radius: 40px;
    font-weight: 600;
    font-size: 14px;
    height: 5vh;
    margin-top: 5vh;
  }

  .housr-button-reverse:focus {
    background-color: #ffffff;
    border-color: #109539;
    border-radius: 40px;
    font-weight: 600;
    font-size: 14px;
    height: 5vh;
    margin-top: 5vh;
  }
}

@media screen and (max-width: 850px) {
  .profile-full-container {
    width: 100% !important;
    display: block;
    height: 100vh !important;
    overflow: hidden;
    position: relative;
    overflow-y: scroll;
  }

  .signup-header {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 25px;
    padding-left: 0px;
  }

  .flex-direction-profile {
    display: flex;
    flex-direction: column;
  }

  .profile-container {
    display: block;
    height: 100% !important;
    width: 100% !important;
    margin-top: 3em;
    z-index: 2;
    position: relative;
  }

  .profile-tile {
    z-index: 2;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 70%;
    margin-bottom: 3em;
    display: flex !important;
    background-color: white;
    flex-direction: column !important;
    height: fit-content;
    box-shadow: 0px 4px 10px 0px #0000001a;
    border-radius: 20px;
  }

  .background-stripe-right-profile {
    z-index: 0;
    width: 50%;
    height: 40%;
    position: absolute;
    right: -20%;
    top: 25%;
  }

  .background-stripe-left-profile {
    z-index: 0;
    width: 30%;
    height: 40%;
    position: absolute;
    left: 0%;
    top: 70%;
  }
}

@media screen and (max-width: 500px) {
  .housr-button-reverse {
    background-color: #ffffff;
    border-color: #109539;
    color: #109539;
    font-weight: 600;
    font-size: 12px;
    border-radius: 40px;
    height: 5vh;
    margin-top: 5vh;
    min-width: 35vw;
  }

  .housr-button-reverse:hover {
    background-color: #ffffff;
    border-color: #109539;
    border-radius: 40px;
    font-weight: 600;
    font-size: 12px;
    height: 5vh;
    margin-top: 5vh;
    min-width: 35vw;
  }

  .housr-button-reverse:focus {
    background-color: #ffffff;
    border-color: #109539;
    border-radius: 40px;
    font-weight: 600;
    font-size: 12px;
    height: 5vh;
    margin-top: 5vh;
    min-width: 35vw;
  }

  .housr-button {
    min-width: 35vw;
    font-size: 12px;
  }

  .housr-button:hover {
    min-width: 35vw;
    font-size: 12px;
  }

  .housr-button:focus {
    min-width: 35vw;
    font-size: 12px;
  }
}
