.sidebar-container {
    background-color: #F5F7FA;
    width: 15vw;
    overflow: hidden;
    z-index: 10;
}

.sidebar-toggle {
    display: none;
    position: absolute;
}

.sidebar-container.open {
    left: 0;
    width: 15vw;
    position: relative;
    z-index: 6;
}

.sidebar-link {
    color: #555555;
    text-decoration: none;
    display: flex;
    justify-content: "flex-start";
    align-items: "center";
    width: 100%;
    padding: .5em;
    margin: 1em 1em 1em 0;
    height: 3em;
    border-radius: 10px;
}

.sidebar-link:hover, .hovering {
    color: #109539;
    font-weight: 700;
}

.active {
    color: #109539;
    background-color: white;
    box-shadow: 1px 1px 5px 1px #00000022;
    font-weight: 700;
}

.sidebar-icon {
    width: 10%;
    height: 100%;
    margin: auto 4%;
}

.sidebar-logo {
    width: 20%;
    margin: 0 auto;
}

.sidebar-logo:hover {
    cursor: pointer;
}

.sidebar-button {
    background-color: #109539;
    border: none;
    margin-bottom: 10%;
    width: 50%;
    font-weight: 600;
}

.sidebar-button:hover, .sidebar-button:active, .sidebar-button:focus {
    background-color: #109539 !important;
}

.close-menu {
    display: none;
}

.sidebar-close {
    display: none;
}

@media screen and (max-width: 1100px) {
    .sidebar-toggle, .sidebar-toggle:hover, .sidebar-toggle:active ,.sidebar-toggle:focus {
        display: block;
        background-color: white !important;
        border: none;
        color: #109539;
        top: 2%;
        height: 5%;
        width: 9%;
        box-shadow: 0px 1px 5px #55555577;
        z-index: 10;
        padding: 0 2%;
    }

    .menu-toggle-image {
        width: 100%;
    }

    .sidebar-close, .sidebar-close:hover, .sidebar-close:active ,.sidebar-close:focus {
        display: flex;
        background-color: white !important;
        border: none;
        color: #109539;
        position: absolute;
        top: 2%;
        right: 10%;
        width: 18%;
        height: 4.5%;
        border-radius: 10px;
        box-shadow: 0px 1px 5px #55555577;
        justify-content: center;
        align-items: center;
        z-index: 10;
        font-size: 20px;
    }

    .close-menu {
        display: block;
        margin: auto;
    }

    .sidebar-container {
        left: -30vw;
        position: relative;
        width: 0;
        transition: left .3s ease-in-out;

    }

    .sidebar-container.open {
        left: 0;
        width: 30vw;
        position: absolute;
        z-index: 10;

    }

    .sidebar-link {
        width: 99%;
    }
    
}

@media screen and (max-width: 800px) {
    .sidebar-container {
        left: -40vw;
    }
    .sidebar-container.open {
        width: 40vw;
    }
}


@media screen and (max-width: 600px) {
    .sidebar-container {
        left: -50vw;
    }
    .sidebar-container.open {
        width: 50vw;
    }
}