@media screen and (max-width: 991px){
  .image-container{
    margin-right: 10px !important;
    height: 60% !important;
  }

  .wallet-card{
    width: 90vw !important;
    margin-left: auto;
    margin-right: auto;
  }

  .available-card-body {
    justify-content: center;
  }

  .wallet-small-card {
    height: 100% !important;
    width: 100% !important;
    justify-content: flex-start !important;
  }

  .wallet-small-title{
    font-size: 15px !important;
  }

  .activity-card, .share-card, .wallet-card{
    width: 100% !important
  }

  .card-body{
    width: 95% !important;
  }

  .activity-referral-card {
    justify-content: space-evenly!important;
    align-self: center !important;
  }

  .wallet-small-card-body{
    justify-content: space-around !important;
    margin-left: auto;
    margin-right: auto;
  }

  .wallet-col {
    width: 100% !important;
  }

  .small-card-container {
    width: 100% !important
  }

  .pending-information {
    margin-left: 5px !important;
  }

  .withdraw-button {
    width: 100% !important;
  }

}

@media screen and (min-width: 991px) and (max-width: 1400px){
  .small-card-container{
  width: 90% !important;
  }

  .image-container{
    margin-right: 20px !important;
    height: 80% !important;
  }

  .wallet-small-card {
    height: 100% !important;
    width: 100% !important;
    justify-content: flex-start !important;
  }

  .activity-card{
    width: 100% !important
  }

  .activity-referral-card {
    justify-content: space-evenly !important;
    align-self: center !important;
    width: 100% !important;
  }

  .withdraw-button {
    width: 100% !important;
  }

  .wallet-money {
    font-size: 26px !important;
  }
}

.wallet-referral-date {
  font-size: 13px !important;
}