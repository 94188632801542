.accordion-button.collapsed{
    background-color: white !important;
    color: black !important
  }

.accordion-button:not(.collapsed) {
    border-color: #109539 !important;
    background-color: #7CBE87 !important;
    color: black !important
  }

  .accordion-button:focus {
    box-shadow: none;
    border-color: rgba(0,0,0,.125);
}